import React from 'react';
import PageTitle from '../components/PageTitle';

export default function About() {
    return (
        <React.Fragment>  
            <PageTitle>
                <div>
                    About
                    <div className="page-title-subheading">
                        This is the About page.
                    </div>
                </div>
            </PageTitle>
            <div className="row">
                <div className="col-lg-12">
                    <div className="main-card mb-3 card">
                        <div className="card-body">
                            <h5 className="card-title">About</h5>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )}

import React from 'react';
import UserSearch from '../components/UserSearch';
import PageTitle from '../components/PageTitle';
import { Link } from 'react-router-dom';

export default function Users() {    
    return (
        <React.Fragment>     
            <PageTitle>
                <div>
                    <div className="page-title">
                        User Accounts                       
                    </div>
                </div>
            </PageTitle>
            <p style={{ paddingLeft:10 }}>
                <Link className="btn btn-primary" 
                    to={{
                        pathname: `usersave/`,
                        state: { systemUserId: 0 },                                    
                    }}
                >New User</Link>
            </p>
            <UserSearch />               
        </React.Fragment>
    )
}

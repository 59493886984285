import React from 'react';

export default function PageTitle({ children }) {
    // Send the user back to the login page if there's no token        
    if (localStorage.getItem("ApiToken") === null || localStorage.getItem("ApiToken") === '')
    {
        //return <Redirect to="/login" />;
    }

    return (
        <div className="app-page-title">
            <div className="page-title-wrapper">
                <div className="page-title-heading">
                    <div className="page-title-icon">
                        <i className="pe-7s-drawer icon-gradient bg-happy-itmeo">
                        </i>
                    </div>
                    { children }
                </div>
            </div>
        </div>
    )
}

import React from 'react';
import UserSave from '../components/UserSave';
import PageTitle from '../components/PageTitle';

export default function User() { 
    return (
        <React.Fragment>     
            <PageTitle>
                <div>
                    <div className="page-title">
                        User Account                    
                    </div>
                </div>
            </PageTitle>         
            <div className="row">
                <div className="col-lg-12">  
                    <UserSave /> 
                </div>
            </div>
        </React.Fragment>
    )
}

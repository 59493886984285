import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import PageTitle from '../components/PageTitle';

class NotFound extends Component {
    render () {

        // Send the user back to the login page if there's no token        
        if (localStorage.getItem("ApiToken") === null || localStorage.getItem("ApiToken") === '')
        {
            return <Redirect to="/login" />;
        }

        return (
            <React.Fragment>  
                <PageTitle>
                    <div>
                        Not Found
                        <div className="page-title-subheading">
                            This Page Could Not Be Found Or Has Not Been Created Yet.
                        </div>
                    </div>
                </PageTitle>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="main-card mb-3 card">
                            <div className="card-body">
                                <h5 className="card-title">Not Found</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default NotFound;

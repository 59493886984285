import React from 'react';
import PhoneCallSearch from '../components/PhoneCallSearch';
import PageTitle from '../components/PageTitle';

export default function Leads() {    
    return (
        <React.Fragment>     
            <PageTitle>
                <div>
                    <div className="page-title">
                        Phone Calls                       
                    </div>
                </div>
            </PageTitle>            
            <PhoneCallSearch />               
        </React.Fragment>
    )
}

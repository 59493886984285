import React, { Fragment, useState, useEffect } from 'react'
import { Link, Redirect, } from 'react-router-dom'
import axios from 'axios';
import { Formik } from 'formik';
import Form from "react-bootstrap/Form";
import * as Yup from 'yup';
import Alert from 'react-bootstrap/Alert';

export default function ForgotPassword(props) {
    const [ state, setState ] = useState({
        alertMessage: ``,
        alertVisible: false,
        alertVariant: '',
        showLoginAlert: false,
        showResetPasswordAlert: false,
        resetPasswordAlertMessage: '',
    });
    const [goToLogin, setGoToLogin] = useState(false);

    useEffect(() => {
        if (props.location.state) {
            const locationState = props.location.state;
            if (locationState.hasOwnProperty("invalid_login") && locationState.hasOwnProperty("message")) {
                setState(state => ({
                    ...state,
                    showResetPasswordAlert: true,
                    resetPasswordAlertMessage: locationState["message"]
                }))
            }
        }
    }, [props.location.state])

    const handleFormSubmission = (values, actions) => {
        // Make a post request
        const { email, tempPassword, password } = values;
        axios({
            url: `${process.env.REACT_APP_API_URL}/api/changepassword`,
            method: 'POST',
            data: {
                LoginId: email,
                OldPassword: tempPassword,
                NewPassword: password
            }
        }).then(response => {
            if (response.data.Code === "success") {
                setGoToLogin(true);
                setState({ 
                    ...state, 
                    alertMessage: `Password Changed Successfully.`,
                    alertVisible: true,
                    alertVariant: 'success',
                    showLoginAlert: true,                    
                })
                
            } else {
                setGoToLogin(false);
                setState({
                    ...state,
                    alertMessage: response.data.Message,
                    alertVisible: true,
                    alertVariant: 'danger',
                    showLoginAlert: false,
                })                
            }
            actions.setSubmitting(false)
        }).catch(error => {
            setState({
                ...state,
                alertMessage: 'Error. Please Try Again Later',
                alertVisible: true,
                alertVariant: 'danger',
            })
            actions.setSubmitting(false)
        })
    }

    const closeAlert = () => {
        setState({
            ...state,
            alertVisible: false
        })
    }

    function equalTo(ref, msg) {
        return Yup.mixed().test({
            name: 'equalTo',
            exclusive: false,
            message: msg,
            params: {
              reference: ref.path,
            },
            test: function(value) {
              return value === this.resolve(ref);
            },
        });
    };

    Yup.addMethod(Yup.string, 'equalTo', equalTo);

    const SignupSchema = Yup.object().shape({
        tempPassword: Yup.string()
            .required('Enter Your Temporary'),
        password: Yup.string()
            .min(10, `Password must be a minimum of 10 characters`)
            .required('Password Is Required'),
        passwordconf: Yup.string()
            .equalTo(Yup.ref('password'), 'Passwords must match')
            .required('Confirm Your Password'),
        email: Yup.string()
            .email('Invalid Email Address')
            .required('Email Address Is Required')
    });

    return (
        <React.Fragment>
        {goToLogin && <Redirect to={{
            pathname: '/',                                   
        }} />}     
        <div className="content py-5">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col-12 col-md-6 mb-3 order-2 order-md-1">
                        <div className="pr-0 pr-md-5">
                            <Alert
                                show={state.showResetPasswordAlert}
                                variant="warning"
                                className="mb-3"
                            >
                                {state.resetPasswordAlertMessage}
                            </Alert>
                            <h2 className="bold-font">Forgot Password</h2>
                            <Formik
                                initialValues={{
                                    tempPassword: '',
                                    email: '',
                                    password: '',
                                    passwordconf: '',
                                }}
                                validationSchema={SignupSchema}
                                onSubmit={(values, actions) => {
                                    // same shape as initial values
                                    handleFormSubmission(values, actions);
                                }}
                            >
                                {({ 
                                    isSubmitting,
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    touched,
                                    errors,
                                }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <div className="form_container">
                                            <div className="form-group">
                                                <label htmlFor="email" className="semibold-font">Email Address</label>
                                                <Form.Control 
                                                    type="text" 
                                                    id="email" 
                                                    name="email" 
                                                    placeholder="Your Email Address" 
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} 
                                                    isInvalid={errors.email && touched.email}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="tempPassword" className="semibold-font">Temporary Password</label>
                                                <Form.Control 
                                                    type="password" 
                                                    id="tempPassword" 
                                                    name="tempPassword" 
                                                    placeholder="Temporary Password" 
                                                    value={values.tempPassword}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} 
                                                    isInvalid={errors.tempPassword && touched.tempPassword}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.tempPassword}
                                                </Form.Control.Feedback>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="password" className="semibold-font">New Password</label>
                                                <Form.Control 
                                                    type="password" 
                                                    id="password" 
                                                    name="password" 
                                                    placeholder="New Password" 
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} 
                                                    isInvalid={errors.password && touched.password}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.password}
                                                </Form.Control.Feedback>
                                            </div>
                                            {/* <div className="form-group">
                                                <small>password must contain a minimum of 10 characters with at least 1 uppercase letter and 1 special letter</small>
                                            </div> */}
                                            <div className="form-group">
                                                <label htmlFor="passwordconf" className="semibold-font">Confirm Password</label>
                                                <Form.Control 
                                                    type="password" 
                                                    id="passwordconf" 
                                                    name="passwordconf" 
                                                    placeholder="Confirm Password" 
                                                    value={values.passwordconf}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} 
                                                    isInvalid={errors.passwordconf && touched.passwordconf}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.passwordconf}
                                                </Form.Control.Feedback>
                                            </div>
                                            <div className="row mt-5">
                                                <div className="col-12 text-center mb-3">
                                                    <button 
                                                        type="submit" 
                                                        className="btn btn-primary"
                                                    >
                                                        {isSubmitting ? (
                                                            <Fragment>
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please Wait...
                                                            </Fragment>
                                                        ) : 'Submit'}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                            <Alert 
                                variant={state.alertVariant} 
                                onClose={() => closeAlert()} 
                                dismissible
                                show={state.alertVisible}
                            >
                                {state.alertMessage}
                            </Alert>
                            <Alert 
                                show={state.showLoginAlert}
                                variant="success"
                            >
                                <u><Link to="/login" style={{ color: '#155724' }}>Click Here To Login To Your Dashboard</Link></u>
                            </Alert>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}

import React, { Fragment, useState, useEffect } from 'react'
import { Link, useLocation, } from 'react-router-dom'
import { Formik, } from "formik";
import Form from "react-bootstrap/Form";
import * as Yup from 'yup';
import "yup-phone";
import axios from 'axios'
import { Card, Button } from 'react-bootstrap';
import moment from 'moment';
import NumberFormat from "react-number-format";

export default function LeadDetails() {    
       
    const location = useLocation();
    const leadId = location.state.leadId || 0 ;    
    const [showTextMessageSentConfirmation, setShowTextMessageSentConfirmation] = useState(false);
    const [showTextMessageError, setShowTextMessageError] = useState(false);
    const [showEmailMessageSentConfirmation, setShowEmailMessageSentConfirmation] = useState(false);
    const [showEmailMessageError, setShowEmailMessageError] = useState(false);
    const access_token = localStorage.getItem("ApiToken");    
    const [leadCampaigns, setLeadCampaigns] = useState([]);    
    const [leadAppointments, setLeadAppointments] = useState([]);
    const [leadPhoneCalls, setLeadPhoneCalls] = useState([]);
    const [leadTextMessages, setLeadTextMessages] = useState([]);
    const [leadNotes, setLeadNotes] = useState([]);
    const [leadEmails, setLeadEmails] = useState([]);
    const [lead, setLead] = useState([]);
    const [twilioPhoneNumbers, setTwilioPhoneNumberse] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (access_token) {
            setIsLoading(true); 
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/api/gettwiliophonenumbers`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                }, 
            }).then(response => {
                let arrTwilioPhoneNumbers = response.data;               
                setTwilioPhoneNumberse(arrTwilioPhoneNumbers);
                setIsLoading(false);
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            })            
        }
    }, [access_token])
    
    useEffect(() => {                        
        if (access_token) {
            setIsLoading(true);
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/api/getlead/${leadId}`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                }, 
            }).then(response => { 
                let arrResults = response.data;
                
                if (arrResults !== null && arrResults.length > 0) {
                    setLead(...arrResults);
                }
                setIsLoading(false);  
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            })                                       
        }        
    }, [access_token, leadId])    

    useEffect(() => {                                  
        if (access_token) {
            setIsLoading(true);
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/api/getleadnotes/${leadId}`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                }, 
            }).then(response => { 
                let arrResults = response.data;
                
                if (arrResults !== null && arrResults.length > 0) {
                    setLeadNotes(arrResults);
                }
                setIsLoading(false);
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            })             
        }        
    }, [access_token, leadId]) 

    useEffect(() => {   
        if (access_token) {
            setIsLoading(true);
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/api/getleadphonecalls/${leadId}`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                }, 
            }).then(response => { 
                let arrResults = response.data;
                
                if (arrResults !== null && arrResults.length > 0) {
                    setLeadPhoneCalls(arrResults);
                }            
                setIsLoading(false);      
            }).catch(error => {                     
                console.log(error);
                setIsLoading(false);
            })             
        }        
    }, [access_token, leadId])    

    useEffect(() => {   
        if (access_token) {
            setIsLoading(true);
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/api/getleadtextmessages`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                }, 
                data: {
                    "LeadId": leadId
                }
            }).then(response => { 
                let arrResults = response.data;
                
                if (arrResults !== null && arrResults.length > 0) {
                    setLeadTextMessages(arrResults);
                }            
                setIsLoading(false);      
            }).catch(error => {                     
                console.log(error);
                setIsLoading(false);
            })             
        }        
    }, [access_token, leadId])    

    useEffect(() => {   
        if (access_token) {
            setIsLoading(true);
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/api/getleadappointments/${leadId}`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                }, 
            }).then(response => { 
                let arrResults = response.data;
                
                if (arrResults !== null && arrResults.length > 0) {
                    setLeadAppointments(arrResults);
                }       
                setIsLoading(false);      
            }).catch(error => {                     
                console.log(error);
                setIsLoading(false);
            })             
        }        
    }, [access_token, leadId]) 

    useEffect(() => {    
        if (access_token) {
            setIsLoading(true);
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/api/getleadcampaigns`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                }, 
                data: {                        
                    "LeadId": leadId,
                } 
            }).then(response => { 
                let arrResults = response.data;
                
                if (arrResults !== null && arrResults.length > 0) {
                    setLeadCampaigns(arrResults);
                }  
                setIsLoading(false); 
            }).catch(error => {                     
                console.log(error);
                setIsLoading(false);
            })                      
        }        
    }, [access_token, leadId])    

    const textMessageSchema = Yup.object().shape({
        phoneFrom: Yup.string()
        .min(11, 'The Text From is required')
        .required('The Text From is required'),
        textMessage: Yup.string()
        .required('Text message is required')
    });

    const emailMessageSchema = Yup.object().shape({
        emailFrom: Yup.string()
            .min(3, 'The Email From Address is required')
            .required('The Email From Address is required'),
        emailTo: Yup.string()
            .min(3, 'The Email To Address is required')
            .required('The Email To Address is required'),
        emailSubject: Yup.string()
            .required('The Email Subject is required'),
        emailBody: Yup.string()
            .required('The Email Body is required'),
        
    });
       
    function submitSendSMS(values, actions) {                            

        const access_token = localStorage.getItem("ApiToken"); 
        const systemUserId = localStorage.getItem("SystemUserId");     
        
        values.phoneTo = '+' + lead.PhoneNumber1;         

        if (access_token) {
            setIsLoading(true);
            setShowTextMessageError(false);
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/api/sendsms`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                },  
                data:
                {
                    "PhoneFrom": values.phoneFrom,
                    "PhoneTo": values.phoneTo,
                    "Message": values.textMessage,
                    "MessageSid": ""
                  }    
            }).then(response => { 
                axios({
                    method: 'POST',
                    url: `${process.env.REACT_APP_API_URL}/api/saveleadtextmessage`,                
                    headers: {                     
                        Authorization: `Bearer ${access_token}`
                    },  
                    data:
                    {
                        "LeadTextMessageId": 0,
                        "LeadId": leadId,
                        "TwilioPhoneNumber": values.phoneFrom,
                        "LeadPhoneNumber": values.phoneTo,
                        "Message": values.textMessage,
                        "CreatedDate": "",
                        "CreatedBySystemUserId": systemUserId,
                        "IsDeleted": false
                      }
                }).then(response => { 
                    axios({
                        method: 'POST',
                        url: `${process.env.REACT_APP_API_URL}/api/getleadtextmessages`,                
                        headers: {                     
                            Authorization: `Bearer ${access_token}`
                        }, 
                        data: {
                            "LeadId": leadId
                        }
                    }).then(response => { 
                        let arrResults = response.data;
                        
                        if (arrResults !== null && arrResults.length > 0) {
                            setLeadTextMessages(arrResults);
                        }            
                        
                        setShowTextMessageSentConfirmation(true);                
                        setTimeout(() => setShowTextMessageSentConfirmation(false), 5000);
                        values.phoneFrom = '';
                        values.textMessage = '';
                        setIsLoading(false);       
                    }).catch(error => {                     
                        console.log(error);
                        setIsLoading(false);
                    })    
                    
                }).catch(error => {                
                    setShowTextMessageError(true); 
                    setIsLoading(false);
                })                     
            }).catch(error => {                
                setShowTextMessageError(true); 
                setIsLoading(false);
            })   
        }
    }

    function sumbitSendEmail(values, actions) {                            

        const access_token = localStorage.getItem("ApiToken"); 
        
        if (access_token) {
            setIsLoading(true);
            setShowEmailMessageError(false);
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/api/sendemail`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                },  
                data:
                {
                    "EmailFrom": values.emailFrom,
                    "EmailTo": values.emailTo,
                    "EmailSubject": values.emailSubject,
                    "EmailBody": values.emailBody,
                    "LeadId": values.leadId,
                    //"CreatedDate": "2022-06-15T21:57:40.854847-05:00",
                    "IsDeleted": false
                }
            }).then(response => { 
                  
            }).catch(error => {                
                
            })   

            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/api/saveleademail`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                },  
                data:
                {
                    "EmailFrom": values.emailFrom,
                    "EmailTo": values.emailTo,
                    "EmailSubject": values.emailSubject,
                    "EmailBody": values.emailBody,
                    "LeadId": values.leadId,
                    //"CreatedDate": "2022-06-15T20:04:47.8771106-05:00",
                    "IsDeleted": false
                  }
            }).then(response => { 
                setShowEmailMessageSentConfirmation(true);                
                setTimeout(() => setShowEmailMessageSentConfirmation(false), 5000);
                values.EmailFrom = 'mytimelift@gmail.com';
                values.EmailTo = leadId > 0 && lead.EmailAddress != null? lead.EmailAddress : '';
                values.EmailSubject = 'Welcome to MyTime Lift!';
                values.EmailBody = '';

                // Refresh the lead email list
                axios({
                    method: 'POST',
                    url: `${process.env.REACT_APP_API_URL}/api/getleademail`,                
                    headers: {                     
                        Authorization: `Bearer ${access_token}`
                    }, 
                    data: {                        
                        "LeadId": leadId,
                    } 
                }).then(response => { 
                    let arrResults = response.data;
                    if (arrResults !== null && arrResults.length > 0) {
                        setLeadEmails(arrResults);
                    }
                    setIsLoading(false);
                }).catch(error => {
                    console.log(error);
                    setIsLoading(false);
                })             
                
                setIsLoading(false);     
            }).catch(error => {                
                setShowEmailMessageError(true); 
                setIsLoading(false);
            })                    
        }
    }

    useEffect(() => {                                  
        if (access_token) {
            setIsLoading(true);
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/api/getleademail`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                }, 
                data: {                        
                    "LeadId": leadId,
                } 
            }).then(response => { 
                let arrResults = response.data;
                if (arrResults !== null && arrResults.length > 0) {
                    setLeadEmails(arrResults);
                }
                setIsLoading(false);
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            })             
        }        
    }, [access_token, leadId]) 

    const styles = {
        cardLabel: {
            verticalAlign:"top", 
            paddingRight:10,
            fontWeight:600
        },
        cardValue: {
            verticalAlign:"top",
            paddingRight:10,
            overflowWrap: "anywhere",
        },
        cardOpportunities: {
            paddingRight: 10,
        }
    }
        
    return (  
        !isLoading?         
        <React.Fragment>   
            <div className="container">
                <div className="card-columns">
                    <Card>
                        <Card.Header style={{backgroundColor:"#BE4D4D", color:"#FFFFFF"}}>Lead</Card.Header>
                        <Card.Title>
                            <p style={{paddingLeft:10, fontWeight:700,}}>
                                {lead.FirstName + ' '} 
                                {lead.MiddleInitial != null && lead.MiddleInitial !== ''? lead.MiddleInitial + ' ' : '' + lead.LastName}
                                {lead.LastName}
                            </p>
                        </Card.Title>
                        <Card.Body>                            
                            <table>
                                <tbody>                               
                                <tr>
                                    <td style={styles.cardLabel}>Primary Phone:</td>
                                    <td style={styles.cardValue}>
                                        <NumberFormat
                                            style={{ border:'none' }}
                                            mask=""
                                            value={lead.PhoneNumber1}
                                            format="#-(###) ###-#####"
                                        />        
                                        <span style={{color:'#ff0000', fontSize:'14px',}}>{lead.IsBogusPhoneNumber1 === true? 'Bogus' : ''}</span>                        
                                    </td>
                                </tr>
                                <tr>
                                    <td style={styles.cardLabel}>Secondary Phone:</td>
                                    <td style={styles.cardValue}>
                                        <NumberFormat
                                            style={{ border:'none' }}
                                            mask=""
                                            value={lead.PhoneNumber2}
                                            format="#-(###) ###-#####"
                                        />
                                        <span style={{color:'#ff0000', fontSize:'14px',}}>{lead.IsBogusPhoneNumber2 === true? 'Bogus' : ''}</span> 
                                    </td>
                                </tr>
                                <tr>
                                    <td style={styles.cardLabel}>Email:</td>
                                    <td style={styles.cardValue}>{lead.EmailAddress != null? lead.EmailAddress : ''}</td>
                                </tr>
                                <tr>
                                    <td style={styles.cardLabel}>Address:</td>
                                    <td style={styles.cardValue}>
                                        {lead.StreetAddress != null? lead.StreetAddress + ',' : ''}
                                        <br />
                                        {lead.City != null? lead.City : ''}, 
                                        <br />
                                        {lead.StateName != null? lead.StateName + ' ' : ''}                                        
                                        {lead.Zipcode != null? lead.Zipcode : ''}                                                                                
                                        {lead.POBox != null && lead.POBox !== ''? '<br />P.O. Box: ' + lead.POBox + '<br />': ''}                                    
                                    </td>
                                </tr>
                                <tr>
                                    <td style={styles.cardLabel}>Birthday:</td>
                                    <td style={styles.cardValue}>{lead.Birthdate != null? moment(lead.Birthdate).format('MM/DD/yyyy') : ''}</td>
                                </tr>
                                <tr>
                                    <td style={styles.cardLabel}>Gender:</td>
                                    <td style={styles.cardValue}>{lead.Gender != null? lead.Gender : ''}</td>
                                </tr>
                                <tr>
                                    <td style={{columnSpan:2}}>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td style={styles.cardLabel}>Ok to Call:</td>
                                    <td style={styles.cardValue}>{lead.OkToCall === true? 'Yes' : 'No'}</td>
                                </tr>
                                <tr>
                                    <td style={styles.cardLabel}>Ok to Email:</td>
                                    <td style={styles.cardValue}>{lead.OkToEmail === true? 'Yes' : 'No'}</td>
                                </tr>
                                <tr>
                                    <td style={styles.cardLabel}>Ok to Text:</td>
                                    <td style={styles.cardValue}>{lead.OkToText === true? 'Yes' : 'No'}</td>
                                </tr>
                                <tr>
                                    <td style={{columnSpan:2}}>&nbsp;</td>
                                </tr>
                                </tbody>
                            </table> 
                            <Link 
                                to={{
                                    pathname: '/leadsave',
                                    state: { leadId: leadId },                                    
                                }}
                            >
                                <Button variant="primary" >
                                    Edit Lead
                                </Button>
                            </Link> 
                        </Card.Body>
                    </Card> 
                    <Card>
                        <Card.Header style={{backgroundColor:"#5581ab", color:"#FFFFFF"}}>Send Text Message</Card.Header>
                        <Card.Title>                                
                        </Card.Title>
                        <Card.Body>
                            <Formik enableReinitialize={true} 
                                initialValues={{                                        
                                    textMessage: '',
                                    phoneFrom: '',
                                }}     
                                            
                                validationSchema={textMessageSchema}
                                onSubmit={(values, actions) => {
                                    // same shape as initial values
                                    submitSendSMS(values, actions);
                                }}
                            >
                                {({ 
                                    isSubmitting,
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    touched,
                                    errors,
                                    setFieldValue,
                            }) => (                                 
                                <React.Fragment>                                          
                                    <Form 
                                        autoComplete="off"
                                        onSubmit={handleSubmit}>
                                        <div className="form_container" style={{ padding:5 }}>
                                            <div className="form-group">
                                                <p style={{ marginLeft:'10px', }}><strong>Primary Phone:</strong>
                                                <NumberFormat
                                                    style={{ border:'none' }}
                                                    mask=""
                                                    value={lead.PhoneNumber1}
                                                    format="#-(###) ###-#####"
                                                /></p>  
                                                <label htmlFor="phoneTo" className="semibold-font"><span style={{ color: "#ff0000" }}>*</span> Text From:</label>
                                                <Form.Control
                                                    className="form-control"
                                                    as="select"
                                                    onChange={handleChange}
                                                    name="phoneFrom"
                                                    id="phoneFrom"
                                                    value={values.phoneFrom}
                                                    isInvalid={errors.phoneFrom && touched.phoneFrom}
                                                    >                                            
                                                        { 
                                                        twilioPhoneNumbers.map((twilio) => (
                                                            <option key={twilio.PhoneNumber} value={twilio.PhoneNumber}>
                                                                { twilio.FriendlyName + ' ' + twilio.PhoneNumber }
                                                            </option>
                                                        )) 
                                                        }
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.phoneFrom}
                                                </Form.Control.Feedback>                                                                           
                                            </div>
                                            <label htmlFor="textMessage" className="semibold-font"><span style={{ color: "#ff0000" }}>*</span> Text Message</label>
                                            <br />
                                            <Form.Control as="textarea"
                                                type="textarea" 
                                                id="textMessage" 
                                                name="textMessage" 
                                                placeholder="Text Message" 
                                                value={values.textMessage}
                                                onChange={handleChange}
                                                onBlur={handleBlur} 
                                                isInvalid={errors.textMessage && touched.textMessage}
                                                //autoComplete="textMessage"
                                                rows={5}
                                                columns={40}
                                                style={{height:'30'}}
                                            />                                    
                                            <Form.Control.Feedback type="invalid">
                                                {errors.textMessage}
                                            </Form.Control.Feedback>                                                 
                                            <br /><br />
                                            <button type="submit" 
                                                className="btn btn-primary" 
                                                disabled={lead.IsBogusPhoneNumber1 || !lead.OkToText}
                                            >
                                            {isLoading ? (
                                                    <Fragment>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please Wait...
                                                    </Fragment>
                                                ) : 'Send Text Message'}
                                            </button>                                        
                                        </div>
                                        { lead.IsBogusPhoneNumber1? (<p style={{ color:'#ff0000', marginLeft:'6px', marginTop:'5px' }}>This lead's primary phone number is marked as Bogus. To enable this, un-check the Bogus Primary Number check-box from thier profile.</p>) : '' } 
                                        { !lead.OkToText? (<p style={{ color:'#ff0000', marginLeft:'6px' }}>This lead does not want to receive text messages. Ok to Text is set to 'No'</p>) : '' }         
                                        <div style={{ display: showTextMessageSentConfirmation === true? 'block' : 'none', border:'solid black thin', backgroundColor:'#c0c0c0', textAlign:'center', }} display=''>
                                            <p style={{ paddingTop: '10px', color:'#0000ff' }}>The text message has been sent!</p>
                                        </div>      
                                        <div style={{ display: showTextMessageError === true? 'block' : 'none', border:'solid red thin', backgroundColor:'#f0c0c0', textAlign:'center', }} display=''>
                                            <p style={{ paddingTop: '10px', color:'#ff0000' }}>An error occured.<br />The text message was not sent out!</p>
                                        </div>    
                                        <br />
                                        {leadTextMessages.map((item, i) => (                                    
                                            <React.Fragment key={item.LeadTextMessageId}> 
                                            <div style={{backgroundColor: i % 2 === 0 ? '#EAE7E6' : 'white', padding:'5px'}}>
                                                <table>
                                                    <tr>
                                                        <td style={styles.cardLabel}>Text From:</td>
                                                        <td style={styles.cardValue}>{item.TwilioPhoneNumber}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={styles.cardLabel}>Text To:</td>
                                                        <td style={styles.cardValue}>{item.LeadPhoneNumber}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={styles.cardLabel}>Message:</td>
                                                        <td style={styles.cardValue}>{item.Message}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={styles.cardLabel}>Created Date:</td>
                                                        <td style={styles.cardValue}>{moment(item.CreatedDate).format('MM/DD/yyyy LT')} EST</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>&nbsp;</td>
                                                    </tr>
                                                </table>  
                                            </div>
                                            </React.Fragment>
                                        ))}          
                                    </Form> 
                                </React.Fragment>                           
                            )}
                            </Formik>
                        </Card.Body>
                    </Card>  
                    <Card>
                        <Card.Header style={{backgroundColor:"#5581ab", color:"#FFFFFF"}}>Send Email Message</Card.Header>
                        <Card.Title>                                
                        </Card.Title>
                        <Card.Body>
                            <Formik enableReinitialize={true} 
                                initialValues={{    
                                    leadId: leadId > 0? lead.LeadId : 0,
                                    emailFrom: 'mytimelift@gmail.com', 
                                    emailTo: leadId > 0 && lead.EmailAddress != null? lead.EmailAddress : '',
                                    emailSubject: 'Welcome to MyTime Lift!',
                                    emailBody: '',
                                }}     
                                            
                                validationSchema={emailMessageSchema}
                                onSubmit={(values, actions) => {
                                    // same shape as initial values
                                    sumbitSendEmail(values, actions);
                                }}
                            >
                                {({ 
                                    isSubmitting,
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    touched,
                                    errors,
                                    setFieldValue,
                            }) => (                                 
                                <React.Fragment>                                          
                                    <Form 
                                        autoComplete="off"
                                        onSubmit={handleSubmit}>
                                        <div className="form_container" style={{ padding:5 }}>
                                            <div className="form-group">
                                                <label htmlFor="firstName" className="semibold-font"><span style={{ color: "#ff0000" }}>*</span> Email From</label>
                                                <Form.Control 
                                                    type="text" 
                                                    id="emailFrom" 
                                                    name="emailFrom" 
                                                    placeholder="Email From" 
                                                    value={values.emailFrom}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} 
                                                    isInvalid={errors.emailFrom && touched.emailFrom}
                                                    autoComplete="emailFrom"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.emailFrom}
                                                </Form.Control.Feedback>
                                                <br />
                                                <label htmlFor="firstName" className="semibold-font"><span style={{ color: "#ff0000" }}>*</span> Email To</label>
                                                <Form.Control 
                                                    type="text" 
                                                    id="emailTo" 
                                                    name="emailTo" 
                                                    placeholder="Email To" 
                                                    value={values.emailTo}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} 
                                                    isInvalid={errors.emailTo && touched.emailTo}
                                                    autoComplete="emailTo"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.emailTo}
                                                </Form.Control.Feedback>
                                                <br />
                                                <label htmlFor="firstName" className="semibold-font"><span style={{ color: "#ff0000" }}>*</span> Email Subject</label>
                                                <Form.Control 
                                                    type="text" 
                                                    id="emailSubject" 
                                                    name="emailSubject" 
                                                    placeholder="Email Subject" 
                                                    value={values.emailSubject}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} 
                                                    isInvalid={errors.emailSubject && touched.emailSubject}
                                                    autoComplete="emailSubject"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.emailSubject}
                                                </Form.Control.Feedback>
                                                <br />
                                                <label htmlFor="firstName" className="semibold-font"><span style={{ color: "#ff0000" }}>*</span> Email Body</label>
                                                <Form.Control as="textarea"
                                                    type="textarea" 
                                                    id="emailBody" 
                                                    name="emailBody" 
                                                    placeholder="Email Body" 
                                                    value={values.emailBody}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} 
                                                    isInvalid={errors.emailBody && touched.emailBody}
                                                    autoComplete="emailBody"
                                                    rows={5}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.emailBody}
                                                </Form.Control.Feedback>                                                                         
                                            </div>                                                
                                            <br />
                                            <button type="submit" className="btn btn-primary" disabled={ !lead.OkToEmail }>
                                            {isLoading ? (
                                                    <Fragment>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please Wait...
                                                    </Fragment>
                                                ) : 'Send Email Message'}
                                            </button>                                        
                                        </div>
                                        { !lead.OkToEmail? (<p style={{ color:'#ff0000', marginLeft:'6px' }}>This lead does not want to receive email messages. Ok to Email is set to 'No'</p>) : '' }         
                                        <div style={{ display: showEmailMessageSentConfirmation === true? 'block' : 'none', border:'solid black thin', backgroundColor:'#c0c0c0', textAlign:'center', }} display=''>
                                            <p style={{ paddingTop: '10px', color:'#0000ff' }}>The email message has been sent!</p>
                                        </div>      
                                        <div style={{ display: showEmailMessageError === true? 'block' : 'none', border:'solid red thin', backgroundColor:'#f0c0c0', textAlign:'center', }} display=''>
                                            <p style={{ paddingTop: '10px', color:'#ff0000' }}>An error occured.<br />The email message was not sent out!</p>
                                        </div>  
                                        <br />
                                        {leadEmails.map((item, i) => (                                    
                                            <React.Fragment key={item.LeadEmailId}> 
                                            <div style={{backgroundColor: i % 2 === 0 ? '#EAE7E6' : 'white', padding:'5px'}}>
                                                <table>
                                                    <tr>
                                                        <td style={styles.cardLabel}>Email From:</td>
                                                        <td style={styles.cardValue}>{item.EmailFrom}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={styles.cardLabel}>Email To:</td>
                                                        <td style={styles.cardValue}>{item.EmailTo}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={styles.cardLabel}>Email Subject:</td>
                                                        <td style={styles.cardValue}>{item.EmailSubject}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={styles.cardLabel}>Email Body:</td>
                                                        <td style={styles.cardValue}>{item.EmailBody}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={styles.cardLabel}>Created Date:</td>
                                                        <td style={styles.cardValue}>{moment(item.CreatedDate).format('MM/DD/yyyy LT')} EST</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>&nbsp;</td>
                                                    </tr>
                                                </table>  
                                            </div>
                                            </React.Fragment>
                                        ))}     
                                    </Form> 
                                </React.Fragment>                           
                            )}
                            </Formik>
                        </Card.Body>
                    </Card>  
                    <Card>
                        <Card.Header style={{backgroundColor:"#5581ab", color:"#FFFFFF"}}>Appointments</Card.Header>
                        <Card.Title>                                
                        </Card.Title>
                        <Card.Body>                                                              
                            {leadAppointments.map((item, i) => (                                    
                                <React.Fragment key={item.AppointmentId}>
                                <div style={{backgroundColor: i % 2 === 0 ? '#EAE7E6' : 'white', padding:'5px'}}>
                                    <table>
                                        <tbody>  
                                        <tr>
                                            <td style={styles.cardLabel}>Appointment Type:</td>
                                            <td style={styles.cardValue}>{item.AppointmentType}</td>
                                        </tr> 
                                        <tr>
                                            <td style={styles.cardLabel}>Appointment Date:</td>
                                            <td style={styles.cardValue}>{moment(item.AppointmentDate).format('MM/DD/yyyy LT')}</td>
                                        </tr>                                    
                                        <tr>
                                            <td style={styles.cardLabel}>Status:</td>
                                            <td style={styles.cardValue}>{item.AppointmentStatus}</td>
                                        </tr> 
                                        <tr>
                                            <td style={styles.cardLabel}>Notes:</td>
                                            <td style={styles.cardValue}>{item.AppointmentNotes}</td>
                                        </tr> 
                                        <tr>
                                            <td style={styles.cardLabel}>Created By:</td>
                                            <td style={styles.cardValue}>{item.CreatedBy}</td>
                                        </tr>     
                                        <tr>
                                            <td style={styles.cardLabel}>Created Date:</td>
                                            <td style={styles.cardValue}>{moment(item.CreatedDate).format('MM/DD/yyyy LT')} EST</td>
                                        </tr>                                                                     
                                        <tr>
                                            <td colSpan="2">
                                                <Link to={{
                                                    pathname: "/leadappointmentsave",
                                                    state: {
                                                        appointmentId: item.AppointmentId,
                                                        leadId: leadId
                                                    }
                                                }}>Edit</Link>    
                                            </td>
                                        </tr>                                    
                                        <tr>
                                            <td colSpan="2">&nbsp;</td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </div>
                                </React.Fragment>
                            ))}                                
                            
                            <Link 
                                to={{
                                    pathname: '/leadappointmentsave',
                                    state: { leadId: leadId, },                                    
                                }}
                            >
                                <br />
                                <Button variant="primary" >
                                    Add Appointment
                                </Button>
                            </Link> 
                        </Card.Body>
                    </Card>                      
                    <Card>
                        <Card.Header style={{backgroundColor:"#5581ab", color:"#FFFFFF"}}>Notes</Card.Header>
                        <Card.Title>                                
                        </Card.Title>
                        <Card.Body>                            
                            {leadNotes.map((item, i) => (   
                                <React.Fragment key={item.LeadNoteId}>
                                <div style={{backgroundColor: i % 2 === 0 ? '#EAE7E6' : 'white', padding:'5px'}}>  
                                    <table>
                                        <tbody>                               
                                        <React.Fragment key={item.LeadNoteId}>                                     
                                        <tr>
                                            <td style={styles.cardLabel}>Note:</td>
                                            <td style={styles.cardValue}>{item.Notes}</td>
                                        </tr>  
                                        <tr>
                                            <td style={styles.cardLabel}>Created Date:</td>
                                            <td style={styles.cardValue}>{moment(item.CreatedDate).format('MM/DD/yyyy LT')} EST</td>
                                        </tr>                                    
                                        <tr>
                                            <td colSpan="2">
                                                <Link to={{
                                                    pathname: "/leadnotesave",
                                                    state: {
                                                        leadNoteId: item.LeadNoteId,
                                                        leadId: leadId
                                                    }
                                                }}>Edit</Link>    
                                            </td>
                                        </tr>                                    
                                        <tr>
                                            <td colSpan="2">&nbsp;</td>
                                        </tr>                                    
                                    </React.Fragment>
                                    </tbody>
                                </table>
                                </div>
                                </React.Fragment>
                                ))}                                
                                
                            <Link 
                                to={{
                                    pathname: '/leadnotesave',
                                    state: { leadId: leadId },                                    
                                }}
                            >
                                <br />
                                <Button variant="primary" >
                                    Add Note
                                </Button>
                            </Link> 
                        </Card.Body>
                    </Card>                     
                    <Card>
                        <Card.Header style={{backgroundColor:"#5581ab", color:"#FFFFFF"}}>Phone Calls</Card.Header>
                        <Card.Title>                                
                        </Card.Title>
                        <Card.Body>
                            {leadPhoneCalls.map((item, i) => (                                    
                                <React.Fragment key={item.LeadPhoneCallId}>
                                <div style={{backgroundColor: i % 2 === 0 ? '#EAE7E6' : 'white', padding:'5px'}}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td style={styles.cardLabel}>Date To Call:</td>
                                                <td style={styles.cardValue}>{moment(item.DateToCall).format('MM/DD/yyyy LT')}</td>
                                            </tr>
                                            <tr>
                                                <td style={styles.cardLabel}>Status:</td>
                                                <td style={styles.cardValue}>{item.Status}</td>
                                            </tr> 
                                            <tr>
                                                <td style={styles.cardLabel}>Notes:</td>
                                                <td style={styles.cardValue}>{item.LeadPhoneCallNotes}</td>
                                            </tr> 
                                            <tr>
                                                <td style={styles.cardLabel}>Created By:</td>
                                                <td style={styles.cardValue}>{item.CreatedBy}</td>
                                            </tr>     
                                            <tr>
                                                <td style={styles.cardLabel}>Created Date:</td>
                                                <td style={styles.cardValue}>{moment(item.CreatedDate).format('MM/DD/yyyy LT')} EST</td>
                                            </tr>                                                                     
                                            <tr>
                                                <td colSpan="2">
                                                    <Link to={{
                                                        pathname: "/leadphonecallsave",
                                                        state: {
                                                            leadPhoneCallId: item.LeadPhoneCallId,
                                                            leadId: leadId
                                                        }
                                                    }}>Edit</Link>    
                                                </td>
                                            </tr>                                    
                                        <tr>
                                            <td colSpan="2">&nbsp;</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                </React.Fragment>
                            ))}                           
                                
                            <Link 
                                to={{
                                    pathname: '/leadphonecallsave',
                                    state: { leadId: leadId },                                    
                                }}
                            >
                                <br />
                                <Button variant="primary"
                                    disabled={lead.IsBogusPhoneNumber1 || !lead.OkToCall}
                                >
                                    Add Phone Call
                                </Button>
                            </Link> 
                            { lead.IsBogusPhoneNumber1? (<p style={{ color:'#ff0000', marginLeft:'6px', marginTop:'5px' }}>This lead's primary phone number is marked as Bogus. To enable this, un-check the Bogus Primary Number check-box from thier profile.</p>) : '' } 
                            { !lead.OkToCall? (<p style={{ color:'#ff0000', marginLeft:'6px', marginTop:'5px' }}>This lead does not want to receive phone calls.<br />Ok to Call is set to 'No'</p>) : '' } 
                        </Card.Body>
                    </Card> 
                    <Card>
                        <Card.Header style={{backgroundColor:"#5581ab", color:"#FFFFFF"}}>Campaigns</Card.Header>
                        <Card.Title>                                
                        </Card.Title>
                        <Card.Body>                                                             
                            {leadCampaigns.map((item, i) => (                                    
                                <React.Fragment key={item.LeadCampaignId}>
                                <div style={{backgroundColor: i % 2 === 0 ? '#EAE7E6' : 'white', padding:'5px'}}>
                                    <table>
                                        <tbody>   
                                            <tr>
                                                <td style={styles.cardLabel}>Name:</td>
                                                <td style={styles.cardValue}>{item.CampaignName}</td>
                                            </tr>     
                                            <tr>
                                                <td style={styles.cardLabel}>Identifier:</td>
                                                <td style={styles.cardValue}>{item.CampaignIdentifier}</td>
                                            </tr>  
                                            <tr>
                                                <td style={styles.cardLabel}>Description:</td>
                                                <td style={styles.cardValue}>{item.CampaignDescription}</td>
                                            </tr>                               
                                            <tr>
                                            <td style={styles.cardLabel}>Created On:</td>
                                                <td style={styles.cardValue}>{moment(item.CreatedDate).format('MM/DD/yyyy LT')} EST</td>
                                            </tr>                                    
                                            <tr>
                                                <td colSpan="2">&nbsp;</td>
                                            </tr>
                                        </tbody>
                                    </table>         
                                </div>
                                </React.Fragment>
                                ))}  
                                                      
                            <Link 
                                to={{
                                    pathname: '/leadcampaignsave',
                                    state: { leadId: leadId },                                    
                                }}
                            >
                                <br />
                                <Button variant="primary" >
                                    Add Lead Campaign
                                </Button>
                            </Link> 
                        </Card.Body>
                    </Card>                      
                </div>
            </div> 
        </React.Fragment> 
        :
        <div className="text-center" style={{width:"100%", height:"100%", }}>
            <div className="spinner-border" role="status" style={{ marginTop:"100px", width:"5rem", height:"5rem",}}>
                <span className="sr-only">Loading...</span>
            </div>
        </div>        
    )
}

import React, { Fragment, useState, useEffect } from 'react'
import { Link, Redirect, useLocation } from 'react-router-dom'
import { Formik, } from "formik";
import Form from "react-bootstrap/Form";
import * as Yup from 'yup';
import "yup-phone";
import axios from 'axios'
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export default function LeadSave() {    
    const access_token = localStorage.getItem("ApiToken");  
    const location = useLocation();
    const leadId = location.state.leadId || 0 ;    
    const [goToLeads, setGoToLeads] = useState(false);      
    const [states, setStates] = useState([]);
    const [lead, setLead] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {   
                     
        if (access_token) {
            setIsLoading(true);
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/api/getlead/${leadId}`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                }, 
            }).then(response => { 
                let arrResults = response.data;
                
                if (arrResults !== null && arrResults.length > 0) {
                    setLead(...arrResults);
                }
                setIsLoading(false);  
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            })                                       
        }        
    }, [access_token, leadId])    

    useEffect(() => {                        
        if (access_token) {
            setIsLoading(true);
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/api/getstates`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                }, 
            }).then(response => { 
                let arrResults = Object.values(response.data);
                
                if (arrResults !== null && arrResults.length > 0) {
                    setStates(arrResults);
                }
                setIsLoading(false);
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            })             
        }  
    }, [access_token])

    const LeadSchema = Yup.object().shape({
        firstName: Yup.string()
        .required('First Name Is Required'),        
        lastName: Yup.string()
        .required('Last Name Is Required'),
        phoneNumber1: Yup.string()
            //.length() > 4
            //.matches(phoneRegExp, "Phone number is not valid")
            .required("Must enter a phone number"),
        phoneNumber2: Yup.string()
            //.matches(phoneRegExp, "Phone number is not valid"),

    });
    
    function handleFormSubmission(values, actions) {   
        const access_token = localStorage.getItem("ApiToken");              
        if (access_token) {
            setIsLoading(true);
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/api/savelead`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                },  
                data: {
                    "LeadId": values.leadId,
                    "FirstName": values.firstName,
                    "MiddleInitial": values.middleInitial,
                    "LastName": values.lastName,
                    "Birthdate": values.birthdate,
                    "Gender": values.gender,
                    "PhoneNumber1": values.phoneNumber1,
                    "PhoneNumber2": values.phoneNumber2,
                    "EmailAddress": values.emailAddress,
                    "StreetAddress": values.streetAddress,
                    "City": values.city,
                    "StateId": values.stateId,
                    "Zipcode": values.zipcode,
                    "PoBox": values.pOBox,
                    "OkToCall": values.okToCall,
                    "OkToEmail": values.okToEmail,
                    "OkToText": values.okToText,
                    "LeadSource": "crm",
                    "PhoneNumber1WithFormatting": "",
                    "PhoneNumber2WithFormatting": "",
                    "LeadStatus": "",
                    "IsBogusPhoneNumber1": values.isBogusPhoneNumber1,
                    "IsBogusPhoneNumber2": values.isBogusPhoneNumber2,
                    }        
            }).then(response => { 
               setGoToLeads(true);
               setIsLoading(false);
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            }) 
        }
    }

    return (
        !isLoading?
        <React.Fragment>      
            {goToLeads && <Redirect to={{
                                    pathname: `lead`,
                                    state: { leadId: leadId },                                    
                                }} />}                                        
            <p className="lead mb-5" style={{ padding:20 }}>Lead Update</p>
                <Formik enableReinitialize={true} 
                    initialValues={{                                        
                        leadId: leadId > 0? lead.LeadId : 0,
                        firstName: leadId > 0 && lead.FirstName != null? lead.FirstName : '',
                        middleInitial: leadId > 0 && lead.MiddleInitial != null? lead.MiddleInitial : '',                        
                        lastName: leadId > 0 && lead.LastName != null? lead.LastName : '',                        
                        birthdate: leadId > 0 && lead.Birthdate != null? moment(lead.Birthdate).format('yyyy-MM-DD') : '',
                        gender: leadId > 0 && lead.Gender != null? lead.Gender : '',
                        phoneNumber1: leadId > 0 && lead.PhoneNumber1 != null? lead.PhoneNumber1 : '',
                        phoneNumber2: leadId > 0 && lead.PhoneNumber2 != null? lead.PhoneNumber2 : '',
                        emailAddress: leadId > 0 && lead.EmailAddress != null? lead.EmailAddress : '',
                        streetAddress: leadId > 0 && lead.StreetAddress != null? lead.StreetAddress : '',
                        city: leadId > 0 && lead.City != null? lead.City : '',
                        stateId: leadId > 0 && lead.StateId != null? lead.StateId : 22,
                        zipcode: leadId > 0 && lead.Zipcode != null? lead.Zipcode : '',                        
                        pOBox: leadId > 0 && lead.POBox != null? lead.POBox : '',
                        okToCall: leadId > 0? lead.OkToCall : true,
                        okToEmail: leadId > 0? lead.OkToEmail : true,
                        okToText: leadId > 0? lead.OkToText : true,
                        isBogusPhoneNumber1: leadId > 0? lead.IsBogusPhoneNumber1 : false,
                        isBogusPhoneNumber2: leadId > 0? lead.IsBogusPhoneNumber2 : false,
                    }}     
                                   
                    validationSchema={LeadSchema}
                    onSubmit={(values, actions) => {
                        // same shape as initial values
                        handleFormSubmission(values, actions);
                    }}
                >
                    {({ 
                        isSubmitting,
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                }) => (
                    <Form 
                        autoComplete="off"
                        onSubmit={handleSubmit}>
                        <div className="form_container" style={{ padding:20 }}>
                            <div className="form-group">
                                <label htmlFor="firstName" className="semibold-font"><span style={{ color: "#ff0000" }}>*</span> First Name</label>
                                <Form.Control 
                                    type="text" 
                                    id="firstName" 
                                    name="firstName" 
                                    placeholder="First Name" 
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.firstName && touched.firstName}
                                    autoComplete="firstName"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.firstName}
                                </Form.Control.Feedback>
                            </div>
                            <div className="form-group">
                                <label htmlFor="middleInitial" className="semibold-font"><span style={{ marginLeft: 10 }}>Middle Initial</span></label>
                                <Form.Control 
                                    type="text" 
                                    id="middleInitial" 
                                    name="middleInitial"
                                    placeholder="Middle Initial" 
                                    value={values.middleInitial}
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.middleInitial && touched.middleInitial}
                                    autoComplete="middleInitial"   
                                    maxLength={1}                                 
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.middleInitial}
                                </Form.Control.Feedback>
                            </div>
                            <div className="form-group">
                                <label htmlFor="lastName" className="semibold-font"><span style={{ color: "#ff0000" }}>*</span> Last Name</label>
                                <Form.Control 
                                    type="text" 
                                    id="lastName" 
                                    name="lastName"
                                    placeholder="Last Name" 
                                    value={values.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.lastName && touched.lastName}
                                    autoComplete="lastName"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.lastName}
                                </Form.Control.Feedback>
                            </div>
                            <div className="form-group">
                                <label htmlFor="birthdate" className="semibold-font"><span style={{ marginLeft: 10 }}>Birthdate</span></label>
                                <Form.Control 
                                    type="date" 
                                    id="birthdate" 
                                    name="birthdate"
                                    placeholder="Birthdate" 
                                    value={values.birthdate}
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.birthdate && touched.birthdate}
                                    autoComplete="birthdate"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.birthdate}
                                </Form.Control.Feedback>
                            </div>
                           
                            <div className="form-group" >

                                <span style={{ marginLeft: 10 }}>
                                    <label htmlFor="male" className="semibold-font" >Male</label>
                                </span>
                                 
                                <span style={{ marginLeft: 10 }}>                                
                                    <Form.Check
                                        inline
                                        type="radio"                                        
                                        checked={values.gender === 'male'? true : false}
                                        id="gender"
                                        name="gender"
                                        value="male"
                                        onChange={handleChange}
                                        onBlur={handleBlur} 
                                        isInvalid={errors.gender && touched.gender} 
                                        autoComplete="gender"                                                    
                                    />
                                </span>
                                <span style={{ marginLeft: 10 }}>
                                    <label htmlFor="female">Female</label>
                                </span>

                                <span style={{ marginLeft: 10 }}>
                                    <Form.Check
                                        inline
                                        type="radio" 
                                        checked={values.gender === 'female'? true : false}
                                        id="gender"
                                        name="gender"
                                        value="female"
                                        onChange={handleChange}
                                        onBlur={handleBlur} 
                                        isInvalid={errors.gender && touched.gender}
                                        autoComplete="gender" 
                                    />
                                </span>
                                <Form.Control.Feedback type="invalid">
                                    {errors.gender}
                                </Form.Control.Feedback>
                            </div>
                            
                            <div className="form-group">
                                <label htmlFor="phoneNumber1" className="semibold-font"><span style={{ color: "#ff0000" }}>*</span> Primary Phone</label>                                          
                                <PhoneInput
                                    inputProps={{
                                        name: 'phoneNumber1',
                                        required: true,
                                        autoFocus: false
                                    }}
                                    //disableCountryCode={true}
                                    countryCodeEditable={false}
                                    country={'us'}
                                    id="phoneNumber1" 
                                    name="phoneNumber1"
                                    value={values.phoneNumber1.toString()}
                                    onChange={(value) => {
                                        values.phoneNumber1 = parseInt(value)
                                    }}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.phoneNumber1 && touched.phoneNumber1}
                                    autoComplete="phoneNumber1"
                                />    
                                <Form.Control.Feedback type="invalid">
                                    {errors.phoneNumber1}
                                </Form.Control.Feedback>
                            </div> 
                            <div className="form-group">
                                <div style={{width:300}}>
                                    <div style={{float:"left",}}>
                                        <span className="semibold-font" style={{marginLeft:"10px", color:"#000000" }}><strong>Bogus Primary Number</strong></span>
                                    </div>
                                    <div style={{float:"left", width:"70px", height:"35px" }}>
                                        <Form.Control 
                                            type="checkbox" 
                                            id="isBogusPhoneNumber1"
                                            name="isBogusPhoneNumber1"
                                            value={values.isBogusPhoneNumber1}
                                            checked={values.isBogusPhoneNumber1 === true? 'checked' : ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur} 
                                            isInvalid={errors.isBogusPhoneNumber1 && touched.isBogusPhoneNumber1}   
                                            style={{ marginLeft:10, height:15 }}  
                                            autoComplete="isBogusPhoneNumber1" 
                                        />
                                    </div>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {errors.isBogusPhoneNumber1}
                                </Form.Control.Feedback>                                
                            </div>
                            <div style={{clear:"both", marginBottom:"20px"}}></div>
                            <div className="form-group">
                                <label htmlFor="phoneNumber2" className="semibold-font"><span style={{ marginLeft: 10 }}>Secondary Phone</span></label>
                                <PhoneInput
                                    inputProps={{
                                        name: 'phoneNumber2',
                                        required: false,
                                        autoFocus: false
                                    }}
                                    //disableCountryCode={true}
                                    countryCodeEditable={false}
                                    country={'us'}
                                    id="phoneNumber2" 
                                    name="phoneNumber2"
                                    value={values.phoneNumber2.toString()}
                                    onChange={(value) => {
                                        values.phoneNumber2 = parseInt(value)
                                    }}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.phoneNumber2 && touched.phoneNumber2}
                                    autoComplete="phoneNumber2"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.phoneNumber2}
                                </Form.Control.Feedback>
                            </div>
                            <div className="form-group">
                                <div style={{width:300}}>
                                    <div style={{float:"left",}}>
                                        <span className="semibold-font" style={{marginLeft:"10px", color:"#000000" }}><strong>Bogus Secondary Number</strong></span>
                                    </div>
                                    <div style={{float:"left", width:"35px", height:"35px" }}>
                                        <Form.Control 
                                            type="checkbox" 
                                            id="isBogusPhoneNumber2"
                                            name="isBogusPhoneNumber2"
                                            value={values.isBogusPhoneNumber2}
                                            checked={values.isBogusPhoneNumber2 === true? 'checked' : ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur} 
                                            isInvalid={errors.isBogusPhoneNumber2 && touched.isBogusPhoneNumber2}   
                                            style={{ marginLeft:10, height:15 }}  
                                            autoComplete="isBogusPhoneNumber2" 
                                        />
                                    </div>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {errors.isBogusPhoneNumber2}
                                </Form.Control.Feedback>                                
                            </div>
                            <div style={{clear:"both", marginBottom:"20px"}}></div>
                            <div className="form-group">
                                <label htmlFor="emailAddress" className="semibold-font"><span style={{ marginLeft: 10 }}>Email Address</span></label>
                                <Form.Control 
                                    type="text" 
                                    id="emailAddress" 
                                    name="emailAddress"
                                    placeholder="Email Address" 
                                    value={values.emailAddress}
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.emailAddress && touched.emailAddress}
                                    autoComplete="emailAddress"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.emailAddress}
                                </Form.Control.Feedback>
                            </div>
                            <div className="form-group">
                                <label htmlFor="streetAddress" className="semibold-font"><span style={{ marginLeft: 10 }}>Street Address</span> </label>
                                <Form.Control 
                                    type="text" 
                                    id="streetAddress" 
                                    name="streetAddress"
                                    placeholder="Street Address" 
                                    value={values.streetAddress}
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.streetAddress && touched.streetAddress}
                                    autoComplete="streetAddress"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.streetAddress}
                                </Form.Control.Feedback>
                            </div>
                            <div className="form-group">
                                <label htmlFor="city" className="semibold-font"><span style={{ marginLeft: 10 }}>City</span> </label>
                                <Form.Control 
                                    type="text" 
                                    id="city" 
                                    name="city"
                                    placeholder="City" 
                                    value={values.city}
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.city && touched.city}
                                    autoComplete="city"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.city}
                                </Form.Control.Feedback>
                            </div>
                            <div className="form-group">
                                <label htmlFor="stateId" className="semibold-font"><span style={{ marginLeft: 10 }}>State</span></label>
                                <br />
                                <Form.Control as="select"
                                    id="stateId" 
                                    name="stateId"
                                    value={values.stateId}
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.stateId && touched.stateId} 
                                    style={{ height: 30 }} 
                                    autoComplete="stateId"                                  
                                >
                                    {states.map(item => (
                                        <option
                                            key={item.StateId}
                                            value={item.StateId}
                                            defaultValue={item.StateId === values.stateId? true : false}
                                        >                            
                                        {item.StateName}
                                        </option>
                                    ))}                                    
                                </Form.Control>                         
                            </div>
                            <div className="form-group">
                                <label htmlFor="zipcode" className="semibold-font"><span style={{ marginLeft: 10 }}>Zip Code</span></label>
                                <Form.Control 
                                    type="text" 
                                    id="zipcode" 
                                    name="zipcode"
                                    placeholder="Zip Code" 
                                    value={values.zipcode}
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.zipcode && touched.zipcode}
                                    autoComplete="zipcode"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.zipcode}
                                </Form.Control.Feedback>
                            </div>
                            <div className="form-group">
                                <label htmlFor="pOBox" className="semibold-font"><span style={{ marginLeft: 10 }}>P.O. Box</span></label>
                                <Form.Control 
                                    type="text" 
                                    id="pOBox" 
                                    name="pOBox"
                                    placeholder="P.O. Box" 
                                    value={values.pOBox}
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.pOBox && touched.pOBox}
                                    autoComplete="pOBox"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.pOBox}
                                </Form.Control.Feedback>
                            </div>
                            <table>
                                <tbody>
                                <tr>
                                    <td>
                                        <label htmlFor="okToCall" className="semibold-font"><span style={{ marginLeft: 10 }}>Ok To Call</span></label>
                                    </td>
                                    <td>
                                        <div className="form-group">
                                            <Form.Control 
                                                type="checkbox" 
                                                id="pOBox"
                                                name="okToCall"
                                                value={values.okToCall}
                                                checked={values.okToCall === true? 'checked' : ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur} 
                                                isInvalid={errors.okToCall && touched.okToCall}   
                                                style={{ marginLeft:10 }}  
                                                autoComplete="pOBox"                              
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.okToCall}
                                            </Form.Control.Feedback>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="okToEmail" className="semibold-font"><span style={{ marginLeft: 10 }}>Ok To Email</span></label>
                                    </td>
                                    <td>
                                        <div className="form-group">
                                            <Form.Control 
                                                type="checkbox" 
                                                id="okToEmail"
                                                name="okToEmail"
                                                value={values.okToEmail}
                                                checked={values.okToEmail === true? 'checked' : ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur} 
                                                isInvalid={errors.okToEmail && touched.okToEmail} 
                                                style={{ marginLeft:10 }}  
                                                autoComplete="okToEmail"                                
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.okToEmail}
                                            </Form.Control.Feedback>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="okToText" className="semibold-font"><span style={{ marginLeft: 10 }}>Ok To Text</span></label>                                
                                    </td>
                                    <td>
                                        <div className="form-group"> 
                                            <Form.Control 
                                                type="checkbox" 
                                                id="okToText"
                                                name="okToText"
                                                value={values.okToText}
                                                checked={values.okToText === true? 'checked' : ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur} 
                                                isInvalid={errors.okToText && touched.okToText} 
                                                style={{ marginLeft:10 }} 
                                                autoComplete="okToText"                                 
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.okToText}
                                            </Form.Control.Feedback>
                                        </div> 
                                    </td>
                                </tr>
                                </tbody>
                            </table>          
                            <div className="row mt-5">
                                <div className="col-12 text-center mb-3">
                                    <button type="submit" className="btn btn-primary">
                                    {isSubmitting ? (
                                        <Fragment>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please Wait...
                                        </Fragment>
                                    ) : 'Save Lead'}
                                    </button>
                                </div>
                                <div className="col-12 text-center my-3">
                                    <Link to={{
                                        pathname: `lead`,
                                        state: { leadId: leadId },                                    
                                    }}><strong>Cancel</strong></Link>
                                </div>                                                
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <Alert 
                variant="danger" 
                onClose={() => this.closeAlert()} 
                dismissible
                show={false}
            >
                { "" }
            </Alert> 
        </React.Fragment>  
        : 
        <div className="text-center" style={{width:"100%", height:"100%", }}>
            <div className="spinner-border" role="status" style={{ marginTop:"100px", width:"5rem", height:"5rem",}}>
                <span className="sr-only">Loading...</span>
            </div>
        </div>   
    )
}

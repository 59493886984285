import React from 'react';
import PageTitle from '../components/PageTitle';
import { CampaignReportTable } from '../components/CampaignReportTable';

export default function About() {
    return (
        <React.Fragment>  
            <PageTitle>
                <div>
                    Campaign Report
                    <div className="page-title-subheading">
                        This report displays campaign performance for all leads within a given date range.
                    </div>
                </div>
            </PageTitle>
            <div className="row">
                <div className="col-lg-12">
                    <div className="main-card mb-3 card">
                        <div className="card-body">
                            <h5 className="card-title"><CampaignReportTable /></h5>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )}

import React, { Fragment, useState, useEffect } from 'react'
import { Link, Redirect, useLocation } from 'react-router-dom'
import { Formik, } from "formik";
import Form from "react-bootstrap/Form";
import * as Yup from 'yup';
import axios from 'axios'
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";

export default function LeadPhoneCallSave() {       
    const location = useLocation();    
    const leadPhoneCallId = location.state.leadPhoneCallId || 0;
    const leadId = location.state.leadId || 0 ;  
    const access_token = localStorage.getItem("ApiToken");    
    const [phoneCallStatuses, setPhoneCallStatuses] = useState([]);
    const [goToLeadPhoneCalls, setGoToLeadPhoneCalls] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [phoneCall, setPhoneCall] = useState([]);

    useEffect(() => {                          
        if (access_token) {
            setIsLoading(true);
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/api/getleadphonecall/${leadPhoneCallId}`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                }, 
            }).then(response => { 
                let arrResults = response.data;
                
                if (arrResults !== null) {
                    setPhoneCall(arrResults); 
                }
                setIsLoading(false);
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            })              
        }  
    }, [access_token, leadPhoneCallId])

    const FormSchema = Yup.object().shape({
        dateToCall: Yup.date()
            //.min(new Date(), "You can't schedule an appointment in the past.")
            .required('Appointment Date Is Required'),
        phoneCallStatusId: Yup.number() 
            .min(1)      
            .required("Phone Call Status is required"),
    });

    useEffect(() => {                    
        if (access_token) {
            setIsLoading(true);
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/api/getphonecallstatus`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                }, 
            }).then(response => { 
                let arrResults = response.data;
                
                if (arrResults !== null && arrResults.length > 0) {
                    setPhoneCallStatuses(arrResults);
                }
                setIsLoading(false);
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            }) 
            
        }        
    }, [access_token])   

    function handleFormSubmission(values, actions) {  
        const access_token = localStorage.getItem("ApiToken");  
        const systemUserId = localStorage.getItem("SystemUserId");              
        if (access_token) {
            setIsLoading(true);
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/api/saveleadphonecall`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                },  
                data: {
                    "LeadPhoneCallId": values.leadPhoneCallId,
                    "LeadId": values.leadId,
                    "DateToCall": moment(values.dateToCall).format('MM/DD/yyyy LT'),
                    "DateCalled": values.dateCalled,
                    "TalkedToSystemUserId": values.talkedToSystemUserId,
                    "PhoneCallStatusId": values.phoneCallStatusId,
                    "AssignedToSystemUserId": values.assignedToSystemUserId,
                    "CreatedBySystemUserId": systemUserId,
                    "IsDeleted": values.isDeleted,
                    "LeadPhoneCallNotes": values.leadPhoneCallNotes,
                  }     
            }).then(response => {              
                setGoToLeadPhoneCalls(true);
                setIsLoading(false);
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            }) 
        }
    }

    return (
        !isLoading? 
        <React.Fragment>      
            {goToLeadPhoneCalls && <Redirect to={{
                                    pathname: `lead`,
                                    state: { leadId: leadId },                                    
                                }} />}                                        
            <p className="lead mb-5" style={{ padding:20 }}>Lead Phone Call</p>                
                <Formik enableReinitialize={true}                 
                    initialValues={{
                            leadPhoneCallId: leadPhoneCallId > 0? phoneCall.LeadPhoneCallId : 0,
                            leadId: leadId,
                            dateToCall: phoneCall.DateToCall != null? moment(phoneCall.DateToCall).format('MM/DD/yyyy LT') : '',
                            dateCalled: phoneCall.DateCalled != null? moment(phoneCall.DateCalled).format('MM/DD/yyyy LT') : '',
                            talkedToSystemUserId: phoneCall.TalkedToSystemUserId != null? phoneCall.TalkedToSystemUserId : null,
                            phoneCallStatusId: phoneCall.PhoneCallStatusId != null? phoneCall.PhoneCallStatusId : 1, // Set default to pending
                            assignedToSystemUserId: phoneCall.AssignedToSystemUserId != null? phoneCall.AssignedToSystemUserId : 1,
                            createdDate: phoneCall.CreatedDate != null? moment(phoneCall.CreatedDate).format('MM/DD/yyyy LT') : null,
                            createdBySystemUserId: phoneCall.CreatedBySystemUserId != null? phoneCall.CreatedBySystemUserId : 1,
                            isDeleted: phoneCall.IsDeleted != null? phoneCall.IsDeleted : false,
                            leadPhoneCallNotes: phoneCall.LeadPhoneCallNotes != null? phoneCall.LeadPhoneCallNotes : '',
                          }}     
                                   
                    validationSchema={FormSchema}
                    onSubmit={(values, actions) => {
                        // same shape as initial values
                        handleFormSubmission(values, actions);
                    }}
                >
                    {({ 
                        isSubmitting,
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                }) => (
                    <Form 
                        autoComplete="off"
                        onSubmit={handleSubmit}>
                        <div className="form_container" style={{ padding:20 }}>                            
                            <div className="col-12 text-center mb-3">
                                <label htmlFor="phoneCallStatus" className="semibold-font"><span style={{ color: "#ff0000" }}>*</span> Phone Call Status</label>
                                <br />
                                <select
                                    id="phoneCallStatusId"
                                    name="phoneCallStatusId"
                                    value={values.phoneCallStatusId}
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.phoneCallStatus && touched.phoneCallStatus}
                                    autoComplete="phoneCallStatusId"
                                >
                                    <option value="" label="--- Select a Phone Call Status ---" />
                                    {phoneCallStatuses.map((phoneCallStatus) => (
                                    <option key={phoneCallStatus.PhoneCallStatusId} value={phoneCallStatus.PhoneCallStatusId}>
                                        {phoneCallStatus.Status}
                                    </option>
                                    ))}
                                </select>
                                {
                                    errors.phoneCallStatusId &&
                                    touched.phoneCallStatusId &&
                                    <div className="input-feedback" class="text-danger">
                                        Phone Call Status is required 
                                    </div>
                                }   
                            </div>  
                            <div className="form-group">
                                <label htmlFor="dateToCall" className="semibold-font"><span style={{ color: "#ff0000" }}>*</span> Date and Time to Call</label>
                                <Datetime
                                    id="dateToCall" 
                                    name="dateToCall" 
                                    value={values.dateToCall} 
                                    onChange={(dateFromValue) => {setFieldValue("dateToCall" , dateFromValue)}}                                    
                                    onBlur={(dateFromValue) => {setFieldValue("dateToCall" , dateFromValue)}} 
                                    isInvalid={errors.dateToCall && touched.dateToCall} 
                                />                                  
                                {
                                    errors.dateToCall &&
                                    touched.dateToCall &&
                                    <div className="input-feedback" class="text-danger">
                                        {errors.dateToCall}
                                    </div>
                                }                                 
                            </div>    
                            <div className="form-group">
                                <label htmlFor="notes" className="semibold-font"><span style={{ color: "#ff0000" }}></span> Notes</label>
                                <Form.Control 
                                    type="text" 
                                    id="leadPhoneCallNotes" 
                                    name="leadPhoneCallNotes" 
                                    placeholder="Notes" 
                                    value={values.leadPhoneCallNotes}
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.leadPhoneCallNotes && touched.leadPhoneCallNotes}
                                    autoComplete="leadPhoneCallNotes"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.leadPhoneCallNotes}
                                </Form.Control.Feedback>
                            </div>        
                            <div className="row mt-5">
                                <div className="col-12 text-center mb-3">
                                    <button type="submit" className="btn btn-primary">
                                    {isSubmitting ? (
                                        <Fragment>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please Wait...
                                        </Fragment>
                                    ) : 'Save Phone Call'}
                                    </button>
                                </div>
                                <div className="col-12 text-center my-3">
                                    <Link to={{
                                        pathname: `lead`,
                                        state: { leadId: leadId },                                    
                                    }}><strong>Cancel</strong></Link>
                                </div>                                                
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <Alert 
                variant="danger" 
                onClose={() => this.closeAlert()} 
                dismissible
                show={false}
            >
                { "" }
            </Alert> 
        </React.Fragment> 
        : 
        <div class="text-center" style={{width:"100%", height:"100%", }}>
            <div class="spinner-border" role="status" style={{ marginTop:"100px", width:"5rem", height:"5rem",}}>
                <span class="sr-only">Loading...</span>
            </div>
        </div>        
    )
}

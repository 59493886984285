import React, { Fragment, useState, useEffect } from 'react'
import { Link, Redirect, useLocation } from 'react-router-dom'
import { Formik } from 'formik';
import Form from "react-bootstrap/Form";
import * as Yup from 'yup';
import axios from 'axios'

export default function LeadCampaignSave() {    
   
    const location = useLocation();
    const leadId = location.state.leadId || 0; 
    const [goToLead, setGoToLead] = useState(false);
    const [isLoading, setIsLoading] = useState(false);   
    const access_token = localStorage.getItem("ApiToken");  
    const [campaigns, setCampaigns] = useState([]);

    useEffect(() => {        
        if (access_token) {
            setIsLoading(true); 
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/api/getcampaigns`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                },                
            }).then(response => {
                let arrCampaigns = Object.values(response.data);
                setCampaigns(arrCampaigns);
                setIsLoading(false);                
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            })          
        }
    }, [access_token,])

    const CampaignSchema = Yup.object().shape({
        campaignId: Yup.string()            
            .required('Campaign Name Is Required'),
    });

    function handleFormSubmission(values, actions) {   
        const access_token = localStorage.getItem("ApiToken");              
        if (access_token) {
            setIsLoading(true);
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/api/saveleadcampaign`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                },  
                data: {                    
                    "LeadId": leadId,
                    "CampaignId": values.campaignId,
                }  
            }).then(response => {              
                setGoToLead(true);
                setIsLoading(false);
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            }) 
        }
    }

    return (
        !isLoading? 
        <React.Fragment>    
            {goToLead && <Redirect to={{
                                    pathname: `lead`,
                                    state: { leadId: leadId },                                    
                                }} />} 
            <p className="lead mb-5" style={{ padding:20 }}>Add Lead Campaign</p>
            <Formik enableReinitialize={true} 
                    initialValues={{      
                    }}
                    validationSchema={CampaignSchema}
                    onSubmit={(values, actions) => {
                        // same shape as initial values
                        handleFormSubmission(values, actions);
                    }}
                >
                    {({ 
                        isSubmitting,
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        errors,
                }) => (
                <Form 
                    autoComplete="off"
                    onSubmit={handleSubmit}>
                    <div className="form_container" style={{ padding:20 }}>
                        <div className="form-group">
                            <div className="row mt-5">
                                <div className="col-12 text-center mb-3">
                                    <label htmlFor="campaignId" className="semibold-font"><span style={{ color: "#ff0000" }}>*</span> Campaign Name</label>
                                    <br />
                                    <select
                                        id="campaignId"
                                        name="campaignId"
                                        value={values.campaignId}
                                        onChange={handleChange}
                                        onBlur={handleBlur} 
                                        isInvalid={errors.campaignId && touched.campaignId}
                                        autoComplete="campaignId"
                                    >
                                        <option value="" label="--- Select a Campaign ---" />
                                        {campaigns.map((campaign) => (
                                        <option key={campaign.CampaignId} value={campaign.CampaignId}>
                                            {campaign.CampaignName}
                                        </option>
                                        ))}
                                    </select>
                                    {
                                        errors.campaignId &&
                                        touched.campaignId &&
                                        <div className="input-feedback" class="text-danger">
                                            Campaign Name is required 
                                        </div>
                                    }  
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-12 text-center mb-3">
                                    <button type="submit" className="btn btn-primary">
                                    {isSubmitting ? (
                                        <Fragment>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please Wait...
                                        </Fragment>
                                    ) : 'Save Lead Campaign'}
                                    </button>
                                </div>
                                <div className="col-12 text-center my-3">
                                    <Link to={{
                                        pathname: `lead`,
                                        state: { leadId: leadId },                                    
                                    }}><strong>Cancel</strong></Link>
                                </div>                                                
                            </div>
                        </div>
                    </div>
                </Form>
                )}
            </Formik>
        </React.Fragment>
        :
        <div class="text-center" style={{width:"100%", height:"100%", }}>
            <div class="spinner-border" role="status" style={{ marginTop:"100px", width:"5rem", height:"5rem",}}>
                <span class="sr-only">Loading...</span>
            </div>
        </div>         
    )
}

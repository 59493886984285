import React, { useMemo, useState, } from "react";
import axios from 'axios'
import { useTable, usePagination, useSortBy, useGlobalFilter, useAsyncDebounce } from "react-table";
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import '../index.css';
import '../table.css'
import { MDBTable, MDBTableBody, MDBTableHead, MDBTableFoot } from 'mdbreact';
import NumberFormat from "react-number-format";

function Leads() {
    const [leads, setLeads] = useState([]);    
    const [isLoading, setIsLoading] = useState(false);
    const [searchCriteria, setSearchCriteria] = useState("");
 
    function handleFormSubmission() {   
        const access_token = localStorage.getItem("ApiToken");                
        if (access_token) {
            setIsLoading(true);
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/api/searchleads`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                },  
                data: {
                    "SearchCriteria": searchCriteria,
                    "TotalCount": 0
                  }      
            }).then(response => { 
                setLeads(response.data);              
                setIsLoading(false);
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            }) 
        }
    }

    function LeadTable({ columns, data }) {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            footerGroups,
            prepareRow,
            page,
            canPreviousPage,
            canNextPage,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
            state: { pageIndex, pageSize },
            state,
            preGlobalFilteredRows,
            setGlobalFilter,
        } = useTable(
            {
                columns,
                data,
                initialState: { pageSize: 50 }
            },
            useGlobalFilter,
            useSortBy,
            usePagination,
        );


        // Render the UI for your table
        return (
            <React.Fragment>                
                <MDBTable id="searchtable" 
                    responsiveSm 
                    responsiveMd 
                    mdbtablescroll="true" 
                    
                    className="table table-striped text-center mb-5" 
                    bordered={true}

                    {...getTableProps()}
                    border={1}
                
                >   
                    <MDBTableHead >
                        <tr>
                            <th
                            colSpan={100}
                            style={{
                                textAlign: 'left',
                                padding: 10,
                                background: '#669999'
                            }}
                            >
                                <GlobalFilter
                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                    globalFilter={state.globalFilter}
                                    setGlobalFilter={setGlobalFilter}
                                />
                            </th>
                        </tr>
                        {headerGroups.map((group) => (
                        <tr {...group.getHeaderGroupProps()}>
                            {group.headers.map((column) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render("Header")}</th>
                            ))}
                        </tr>
                        ))}
                    </MDBTableHead>
                    <MDBTableBody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);

                        return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                            return (
                                <td  style={{textAlign:"left"}} {...cell.getCellProps()}>{cell.render("Cell")}</td>
                            );
                            })}
                        </tr>
                        );
                    })}
                    </MDBTableBody>
                    <MDBTableFoot>
                    {footerGroups.map((group) => (
                        <tr {...group.getFooterGroupProps()}>
                        {group.headers.map((column) => (
                            <td {...column.getFooterProps()}>{column.render("Footer")}</td>
                        ))}
                        </tr>
                    ))}
                    </MDBTableFoot>
                </MDBTable>
                <div className="pagination">
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {"<<"}
                    </button>{" "}
                    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {"<"}
                    </button>
                    {" "}
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                        {">"}
                    </button>
                    {" "}
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {">>"}
                    </button>
                    {" "}
                    <span>
                        Page{" "}
                        <strong>
                            {pageIndex + 1} of {pageCount}
                        </strong>{" "}
                    </span>
                    <span>
                        | Go to page:{" "}
                        <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={(e) => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                            gotoPage(page);
                            }}
                            style={{ width: "100px" }}
                        />
                    </span>
                    {" "}
                    <select
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 50, 100, 500].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                        ))}
                    </select>
            </div>
            </React.Fragment>
        );
    }

    function GlobalFilter({
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter,
    }) {
        const count = preGlobalFilteredRows.length
        const [value, setValue] = React.useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 200)

        return (
            <span>
                Filter Results:{' '}
                <input
                    id="searchCriteria"
                    name="searchCriteria"
                    className="form-control"
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);                     
                    }}
                    placeholder={`${count} records...`}
                />
            </span>
        )
    }

    function formatDate(date) {
        return date !== null? <Moment format='MM/DD/YYYY hh:mm:ss A' date={date}></Moment> : ''
    }

    const columns = useMemo(
        () => [
            {
                Header: "Leads",
                columns: [                    
                    {
                        Header: "First Name",
                        accessor: "FirstName", 
                        Cell: ({ cell: { value }, row: { original } }) => 
                            <Link 
                                to={{
                                    pathname: `lead`,
                                    state: { leadId: original.LeadId },                                    
                                }}
                            >{value}</Link>                       
                    },
                    {
                        Header: "Middle Initial",
                        accessor: "MiddleInitial",
                        //disableSortBy: true
                    },
                    {
                        Header: "Last Name",
                        accessor: "LastName"
                    },
                    {
                        Header: "Status", 
                        accessor: "LeadStatus"
                    },
                    {
                        Header: "Primary Phone",
                        accessor: PhoneNumber1 => {
                            return <NumberFormat
                                style={PhoneNumber1.PhoneNumber1 == null || PhoneNumber1.PhoneNumber1.toString().length > 10? { border:'none' } : { display: 'none'}}
                                mask=""
                                value={PhoneNumber1.PhoneNumber1}
                                format="#-(###) ###-#####"
                                readOnly={true}
                            />
                        }
                    },
                    {
                        Header: "Secondary Phone",
                        accessor: PhoneNumber2 => {
                            return <NumberFormat
                                style={PhoneNumber2.PhoneNumber2 == null || PhoneNumber2.PhoneNumber2.toString().length > 10? { border:'none' } : { display: 'none'}}
                                mask=""
                                value={PhoneNumber2.PhoneNumber2}
                                format="#-(###) ###-#####"
                                readOnly={true}
                            />
                        }
                    },
                    {
                        Header: "Address",
                        accessor: "StreetAddress",
                    },
                    {
                        Header: "City",
                        accessor: "City",
                    },
                    {
                        Header: "State",
                        accessor: "StateName",
                    },
                    {
                        Header: "Zip Code",
                        accessor: "Zipcode",
                    },
                    {
                        Header: "Email Address",
                        accessor: "EmailAddress"
                    },
                    {
                        Header: "Created Date (EST)",
                        accessor: Lead => {
                            return formatDate(Lead.CreatedDate) 
                        }
                    },
                ],
                Footer: "",
            },
            
        ],
        []
    );

    const data = leads;
  
    return !isLoading? 
        <React.Fragment>
            <div style={{backgroundColor:'#669999'}}>
                <form>
                    
                        <label htmlFor="searchCriteria" style={{marginLeft:10, marginBottom:0, color:'#ffffff'}}>Search: </label>
                        <span style={{padding:10,}}>
                            <input
                                type="text"                            
                                className="form-control"
                                style={{width:'98%',
                                        marginLeft:'auto',
                                        marginRight:'auto',
                                        marginBottom:'10px'}}
                                id="searchCriteria"
                                name="searchCriteria"
                                placeholder="Search criteria - name, address, phone"
                                value={searchCriteria}
                                onChange={e => {setSearchCriteria(e.target.value)}}
                            />
                        </span>
                        <span>
                            <input 
                                type="button"
                                style={{background:'#ad5ba4', 
                                        color:'#ffffff',
                                        borderRadius:'6px', 
                                        height:'35px',
                                        marginBottom:'10px'}}
                                value="Search Database"
                                onClick={handleFormSubmission} 
                            />
                        </span>
                   
                </form>
            </div>
            <LeadTable columns={columns} data={data} />            
        </React.Fragment> 
    : 
    <div className="text-center" style={{width:"100%", height:"100%", }}>
        <div className="spinner-border" role="status" style={{ marginTop:"100px", width:"5rem", height:"5rem",}}>
            <span className="sr-only">Loading...</span>
        </div>
    </div>;
}

export default Leads;

import React, { useState, useEffect} from 'react';
import axios from 'axios'
import SalesFunnel from '../assets/images/sales-funnel.jpg'; 

export default function SalesFunnelStats() {
    const access_token = localStorage.getItem("ApiToken");    
    const [salesFunnelStats, setSalesFunnelStats] = useState([]);  
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {    
        if (access_token) {
            setIsLoading(true);
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/api/getsalesfunnelstats`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                }, 
            }).then(response => { 
                let arrResults = response.data;
                
                if (arrResults !== null) {
                    setSalesFunnelStats(arrResults);
                }  
                setIsLoading(false);   
            }).catch(error => {                     
                console.log(error);
                setIsLoading(false);
            })                    
        }        
    }, [access_token])

    return (
        !isLoading?
        <div
            style={{
                width:"100%",
            }}
        >
            <div 
                style={{                  
                    marginLeft:"auto",
                    marginRight:"auto",
                    width:"50%",
                    float:"left",
                }}>
                <img style={{ marginLeft:"auto", marginRight:"auto",}} className="img-responsive img-center" src={ SalesFunnel } alt=""></img>                         
            </div>
            <div
                style={{      
                    backgroundColor:"#ffffff",         
                    marginLeft:"auto",
                    marginRight:"auto",
                    marginTop:"5%",
                    float:"left",
                }}
            >
                <div style={{backgroundColor:"#E5E5E4", minWidth:"252px", padding:"10px"}}>
                    <table>
                        <tbody>
                            <tr>
                                <td colSpan={3} style={{ fontWeight:"700"}}>Statistics</td>
                            </tr>
                            <tr>
                                <td>Leads:</td>
                                <td>&nbsp;</td>
                                <td style={{ textAlign:"right" }}>{ salesFunnelStats.LeadsCreated }</td>
                            </tr>
                            <tr>
                                <td>Consultation Appointments:</td>
                                <td>&nbsp;</td>
                                <td style={{ textAlign:"right" }}>{ salesFunnelStats.ConsultationAppointments }</td>
                            </tr>
                            <tr>
                                <td>Surgery Appointments:</td>
                                <td>&nbsp;</td>
                                <td style={{ textAlign:"right" }}>{ salesFunnelStats.SurgeryAppointments }</td>
                            </tr>
                            <tr>
                                <td>Suture Removal Appointments:</td>
                                <td>&nbsp;</td>
                                <td style={{ textAlign:"right" }}>{ salesFunnelStats.SutureRemovalAppointments }</td>
                            </tr>
                            <tr>
                                <td>Follow-Up Appointments:</td>
                                <td>&nbsp;</td>
                                <td style={{ textAlign:"right" }}>{ salesFunnelStats.FollowUpAppointments }</td>
                            </tr>
                            <tr>
                                <td>Phone Calls Scheduled:</td>
                                <td>&nbsp;</td>
                                <td style={{ textAlign:"right" }}>{ salesFunnelStats.PhoneCallsScheduled }</td>
                            </tr>  
                        </tbody>
                    </table>
                </div>
            </div>
        </div> 
        :
        <div class="text-center" style={{width:"100%", height:"100%", }}>
            <div class="spinner-border" role="status" style={{ marginTop:"100px", width:"5rem", height:"5rem",}}>
                <span class="sr-only">Loading...</span>
            </div>
        </div>      
    )
}
import { Component, } from 'react'

class NavigationData extends Component {
    NavigationDataAdmin() {
        return [
            { 'navId': 1, 'navTo': '/home', 'className': 'metismenu-icon pe-7s-smile', 'menuLabel': 'Home' },
            { 'navId': 2, 'navTo': '/appointments', 'className': 'metismenu-icon pe-7s-albums', 'menuLabel': 'Appointments' },
            { 'navId': 3, 'navTo': '/campaigns', 'className': 'metismenu-icon pe-7s-albums', 'menuLabel': 'Campaigns' },
            { 'navId': 4, 'navTo': '/leads', 'className': 'metismenu-icon pe-7s-albums', 'menuLabel': 'Leads' },
            { 'navId': 5, 'navTo': '/phonecalls', 'className': 'metismenu-icon pe-7s-albums', 'menuLabel': 'Phone Calls' },
            { 'navId': 6, 'navTo': '/reports', 'className': 'metismenu-icon pe-7s-albums', 'menuLabel': 'Reports' },
            { 'navId': 7, 'navTo': '/users', 'className': 'metismenu-icon pe-7s-users', 'menuLabel': 'User Accounts' },
            { 'navId': 8, 'navTo': '/', 'className': 'metismenu-icon pe-7s-mouse', 'menuLabel': 'Logout' },
        ];
    }

    NavigationDataCampaignManager() {
        return [
            { 'navId': 1, 'navTo': '/home', 'className': 'metismenu-icon pe-7s-smile', 'menuLabel': 'Home' },
            { 'navId': 2, 'navTo': '/appointments', 'className': 'metismenu-icon pe-7s-albums', 'menuLabel': 'Appointments' },
            { 'navId': 3, 'navTo': '/campaigns', 'className': 'metismenu-icon pe-7s-albums', 'menuLabel': 'Campaigns' },
            { 'navId': 4, 'navTo': '/leads', 'className': 'metismenu-icon pe-7s-albums', 'menuLabel': 'Leads' },
            { 'navId': 5, 'navTo': '/phonecalls', 'className': 'metismenu-icon pe-7s-albums', 'menuLabel': 'Phone Calls' },
            { 'navId': 6, 'navTo': '/reports', 'className': 'metismenu-icon pe-7s-albums', 'menuLabel': 'Reports' },
            { 'navId': 7, 'navTo': '/userdetail', 'className': 'metismenu-icon pe-7s-users', 'menuLabel': 'User Account' },
            { 'navId': 8, 'navTo': '/', 'className': 'metismenu-icon pe-7s-mouse', 'menuLabel': 'Logout' },
        ];
    }
}

export default NavigationData;

import React, { Fragment, useState, useEffect } from 'react'
import { Link, Redirect, useLocation } from 'react-router-dom'
import { Formik, } from "formik";
import Form from "react-bootstrap/Form";
import * as Yup from 'yup';
import axios from 'axios'
import Alert from 'react-bootstrap/Alert';

export default function LeadNoteSave() {    
   
    const location = useLocation();
    const leadNoteId = location.state.leadNoteId || 0;
    const leadId = location.state.leadId || 0 ; 
    const [goToLeads, setGoToLeads] = useState(false);
    const access_token = localStorage.getItem("ApiToken");  
    const [note, setNote] = useState([]);
    const [isLoading, setIsLoading] = useState(false); 

    useEffect(() => {                            
        if (access_token) {
            setIsLoading(true);  
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/api/getleadnote/${leadNoteId}`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                }, 
            }).then(response => { 
                let arrResults = response.data;

                if (arrResults !== null) {
                    setNote(arrResults); 
                }
                setIsLoading(false);
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            })              
        }        
    }, [access_token, leadNoteId])

    const LeadSchema = Yup.object().shape({
        notes: Yup.string()
        .required('Notes are required'),
    });

    function handleFormSubmission(values, actions) {          
        const access_token = localStorage.getItem("ApiToken");             
        if (access_token) {
            setIsLoading(true);
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/api/saveleadnote`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                },  
                data: {
                    "LeadNoteId": leadNoteId,
                    "LeadId": leadId,
                    "Notes": values.notes,
                    "CreatedDate": "2021-06-21T12:36:49.6663159-04:00"
                  }        
            }).then(response => { 
               setGoToLeads(true); 
               setIsLoading(false);
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            }) 
        }
    }

    return (
        !isLoading?
        <React.Fragment>      
            {goToLeads && <Redirect to={{
                                    pathname: `lead`,
                                    state: { leadId: leadId },                                    
                                }} />}                                        
            <p className="lead mb-5" style={{ padding:20 }}>Note Save</p>
                <Formik enableReinitialize={true} 
                    initialValues={{                                        
                        leadId: leadId > 0? note.NoteId : 0,
                        notes: leadId > 0 && note.Notes != null? note.Notes : '',
                    }}     
                                   
                    validationSchema={LeadSchema}
                    onSubmit={(values, actions) => {
                        // same shape as initial values
                        handleFormSubmission(values, actions);
                    }}
                >
                    {({ 
                        isSubmitting,
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                }) => (
                    <Form 
                        autoComplete="off"
                        onSubmit={handleSubmit}>
                        <div className="form_container" style={{ padding:20 }}>
                            <div className="form-group">
                                <label htmlFor="notes" className="semibold-font"><span style={{ color: "#ff0000" }}>*</span> Notes</label>
                                <Form.Control 
                                    type="text" 
                                    id="notes" 
                                    name="notes" 
                                    placeholder="Notes" 
                                    value={values.notes}
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.notes && touched.notes}
                                    autoComplete="notes"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.notes}
                                </Form.Control.Feedback>
                            </div>        
                            <div className="row mt-5">
                                <div className="col-12 text-center mb-3">
                                    <button type="submit" className="btn btn-primary">
                                    {isSubmitting ? (
                                        <Fragment>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please Wait...
                                        </Fragment>
                                    ) : 'Save Note'}
                                    </button>
                                </div>
                                <div className="col-12 text-center my-3">
                                    <Link to={{
                                        pathname: `lead`,
                                        state: { leadId: leadId },                                    
                                    }}><strong>Cancel</strong></Link>
                                </div>                                                
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <Alert 
                variant="danger" 
                onClose={() => this.closeAlert()} 
                dismissible
                show={false}
            >
                { "" }
            </Alert> 
        </React.Fragment>     
        :
        <div class="text-center" style={{width:"100%", height:"100%", }}>
            <div class="spinner-border" role="status" style={{ marginTop:"100px", width:"5rem", height:"5rem",}}>
                <span class="sr-only">Loading...</span>
            </div>
        </div>   
    )
}

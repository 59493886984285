import React from 'react';
import AppointmentSave from '../components/LeadAppointmentSave';
import PageTitle from '../components/PageTitle';

export default function LeadNoteSave() { 
    return (
        <React.Fragment>     
            <PageTitle>
                <div>
                    <div className="page-title">
                        Lead Appointments                       
                    </div>
                </div>
            </PageTitle>         
            <div className="row">
                <div className="col-lg-12">  
                    <AppointmentSave /> 
                </div>
            </div>
        </React.Fragment>
    )
}

import React from 'react';
import CampaignDetail from '../components/CampaignDetails';
import PageTitle from '../components/PageTitle';

export default function Campaign() { 
    return (
        <React.Fragment>     
            <PageTitle>
                <div>
                    <div className="page-title">
                        Campaign                      
                    </div>
                </div>
            </PageTitle>         
            <div className="row">
                <div className="col-lg-12">                
                    <CampaignDetail /> 
                </div>
            </div>
        </React.Fragment>
    )
}
